import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../../components/header"
import Footer from "../../components/footer"
import Seo from "../../components/seo"
import Accesibilidad from "../../components/accesibilidad"

const Busqueda = props => {
  let search = props.location?.search
    ?.slice(1,)
    ?.replace(/[^a-zA-Z ]/g, " ")
    ?.replace("-", " ")
    ?.toLowerCase()

  let site = "personas"

  if (typeof window !== "undefined" && site) {
    site = window.localStorage.getItem("currentSite")
  }

  const query = useStaticQuery(graphql`
    query Buscadordetalle {
      allStrapiPages {
        edges {
          node {
            site
            slug
            title
            content
          }
        }
      }
    }
  `)

  const paginas = query.allStrapiPages.edges
  let url_global = process.env.GATSBY_API_URL
  let image_url =
    "url(" + url_global + "/uploads/" + "valla_03_6e3f99d9a6.jpg" + ")"

  function goTolink(link) {
    if (typeof window !== "undefined") {
      window.location.href = link
    }
  }

  return (
    <>
      <Accesibilidad />
      {site === "undefined" && <Header site="personas" />}

      {site !== "undefined" && <Header site={site} />}

      <Seo title="" />

      <div
        className="absolute w-screen altura_banner_buscador ancho_banner_fondo_ventaja_buscador altura_banner_quienes_somos lg:w-screen altura_banner_quienes_somos posicion_lado_banner_ventaja -top-0 bg-cover bg-right"
        style={{
          backgroundImage: `${image_url}`,
        }}
      ></div>
      <div
        className="container mx-auto titulo_buscador_sm text-poppins-bold lg:relative titulo_ventaja_lg"
        style={{
          color: "#256238",
          paddingLeft: "3rem",
          top: "9rem",
          fontSize: "3rem",
          right: "0rem",
        }}
      >
        Buscador
      </div>

      <br></br>
      <br></br>
      <p className="relative container mx-auto buscador_titulo pl-12 title text-poppins-bold green-primary top-20 mt-64 mitexto mitexto_color">
        RESULTADOS DE BUSQUEDA
      </p>

      <div
        className="container mx-auto container_dos filtro_buscador flex flex-wrap gap-4 mitexto -mt-8"
        style={{
          justifyContent: "flex-start",
          flexDirection: "column",
          paddingLeft: "14rem",
          paddingTop: "10rem",
        }}
      >
        <ul>
          {paginas.map(({ node: pagina }, index) => {
            if (
              pagina.title !== "Ninguno" &&
              (pagina.slug
                ?.replace(/[^a-zA-Z ]/g, " ")
                .toLowerCase()
                .indexOf(search) !== -1 ||
                pagina.title
                  .replace(/[^a-zA-Z ]/g, " ")
                  .toLowerCase()
                  .indexOf(search) !== -1)
            ) {
              const enlaceTotal = "/" + pagina.site + "/" + pagina.slug
              const enlaceTotal_dos = "/" + pagina.slug
              return (
                <li
                  style={{ listStyle: "auto " }}
                  className="mitexto_color text-poppins-bold green-secundary"
                  key={index.toString()}
                >
                  {pagina.site === null && (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        goTolink(enlaceTotal_dos)
                      }}
                    >
                      <div className="mitexto_color text-poppins-bold green-secundary">
                        {pagina.title}
                      </div>
                    </span>
                  )}

                  {pagina.site !== null && (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        goTolink(enlaceTotal)
                      }}
                    >
                      <div className="mitexto_color text-poppins-bold green-secundary">
                        {pagina.title}
                      </div>
                    </span>
                  )}
                </li>
              )
            }
          })}
        </ul>
      </div>
      <Footer />
    </>
  )
}

Busqueda.propTypes = {
  siteTitle: PropTypes.string,
}

Busqueda.defaultProps = {
  siteTitle: "",
}

export default Busqueda
